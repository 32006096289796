import type { TypeProviderPublic } from '@lib/provider-types'
import { Link } from '@shc/ui'
import Ratings from '@components/ratings'

export interface ProviderRatingsProps {
  provider: TypeProviderPublic
  link?: string
}

const ProviderRatings = ({ provider, link }: ProviderRatingsProps) => {
  if (provider.review_count && provider.review_count > 29) {
    return (
      <div className="flex flex-row items-center">
        {provider.review_rating && <Ratings value={provider.review_rating} />}
        {link ? (
          <Link className="pl-4" noUnderline href={link} aria-label="Provider Ratings">
            {provider.review_count} ratings
          </Link>
        ) : (
          <div className="pl-4">{provider.review_count} ratings</div>
        )}
      </div>
    )
  } else {
    return (
      <Link noUnderline href="/doctors#doctor-ratings-reviews-determined">
        No ratings available
      </Link>
    )
  }
}

export default ProviderRatings
